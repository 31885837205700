<template>
  <div>
    <b-button @click="startConsole" variant="primary"
      :block="!onlyIcon"
      v-b-tooltip.hover.bottom="onlyIcon ? $t('LABS.open_console') : ''">>
      <b-icon icon="terminal-fill" variant="white" />
      <span v-if="!onlyIcon" class="ml-1">{{ $t('LABS.open_console') }}</span>
    </b-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ToastsMixin from '@/services/mixins/toasts.mixins'

export default {
  name: 'LabConsoleLaunchBtn',
  mixins: [ToastsMixin],
  props: {
    onlyIcon: { type: Boolean, required: false, default: false },
    labId: { required: true }
  },
  methods: {
    ...mapActions({
      startConsoleAction: 'labs/startConsole'
    }),
    openNewTab (url) {
      try {
        window.open(url, '_blank').focus()
      } catch (err) {
        this.showErrorToast(this.$t('ERRORS.cannot_open_new_tab'))
      }
    },
    startConsole () {
      this.startConsoleAction(this.labId)
        .then(response => {
          if (typeof response.data.data.url === 'undefined') throw Error('missing_url')
          if (!response.data.data.url) throw Error('invalid_url')

          this.openNewTab('/terminal')
        })
        .catch(_ => { this.showErrorToast(this.$t('LABS.ERRORS.general')) })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
