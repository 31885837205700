<template>
  <div>
    <b-button
      @click="$refs.modalHint.show()"
      variant="secondary"
      :block="!onlyIcon"
      v-b-tooltip.hover.bottom="onlyIcon ? $t('HINTS.unlock') : ''"
    >
      <font-awesome-icon :icon="['fa', 'question-circle']" size="1x" />
      <span v-if="!onlyIcon" class="ml-1">{{ $t('HINTS.unlock') }}</span>
    </b-button>

    <b-modal
      ref="modalHint"
      :title="$t('HINTS.unlock')"
      :ok-title="$t('ACTIONS.accept')"
      :cancel-title="$t('ACTIONS.cancel')"
      @ok="handleSubmit"
    >

    <p>{{ $t('HINTS.unlock_modal_description') }}</p>

    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'UnlockHintModal',
  props: {
    onlyIcon: { type: Boolean, required: false, default: false },
    machineId: { required: true },
    labId: { required: false, default: null },
    pillId: { required: false, default: null }
  },
  methods: {
    ...mapActions({
      unlockHintAction: 'labs/unlockHint',
      unlockPillHintAction: 'pills/unlockHint'
    }),
    handleSubmit () {
      return this.action(this.params)
        .then(response => {
          if (response.status !== 200 || response.data.status !== 'ok') throw response
          this.$emit('success', response.data.data)
        })
        .catch(_ => { this.$emit('error') })
    }
  },
  computed: {
    isLabMachine () { return this.labId !== null },
    action () { return this.isLabMachine ? this.unlockHintAction : this.unlockPillHintAction },
    params () { return this.isLabMachine ? { lab: this.labId, machine: this.machineId } : { pill: this.pillId, machine: this.machineId } }
  }
}
</script>
