<template>
  <div class="home">
    <loading :loading="isLoading"></loading>
    <SectionHeader
    :name="machine.name"
    :loading="isLoading"
    has-back-button
    >
    </SectionHeader>
    <div class="main-container py-3 px-md-5 overflow-x-hidden p-relative">
      <b-row class="machine-detail-container justify-content-center">
        <b-col sm="12" md="6" lg="5" offset-md="6" offset-lg="7" class="mt-3">
          <Card no-shadow>
            <template v-slot:title>
              <div class="flex justify-content-between">
                <span class="font-weight-bold">{{ $t('PILLS.description') }}</span>
                <div>
                  <StatusPill :status="machine.status" />
                  <Pill class="ml-2" v-if="isMachineBlockedByOther" :cssClasses="{ error : true }" v-b-tooltip="$t('LAB_MACHINE.locked_by_other')">
                    <b-icon icon="lock" />
                  </Pill>
                </div>
              </div>
            </template>
            <template v-slot:content>
              <p v-html="translatedDescription" />

              <div>
                <span class="font-weight-bold">{{ $t('MACHINES.connection_title') }}</span><br>
                <p v-if="machine.connectionInfo">{{ translatedConnectionInfo }}</p>
                <p v-else>{{ $t('MACHINES.no_connection_info') }}</p>

                <span v-if="machine.instanceIp" class="font-weight-bold">{{ $t('MACHINES.ip') }}</span><br v-if="machine.instanceIp">
                <span v-if="machine.instanceIp">{{ machine.instanceIp }}</span>
              </div>

              <b-row class="mt-2">
                <b-col cols="6" md="4" class="mt-1">
                  <span class="font-weight-bold" style="font-size: 0.8rem;">{{ $t('PILLS.difficulty') }}</span><br>
                  <DifficultyPill :difficulty="machine.difficulty" class="mr-2" />
                </b-col>
                <b-col cols="6" md="4" class="mt-1">
                  <span class="font-weight-bold" style="font-size: 0.8rem;">{{ $t('MACHINES.so') }}</span><br>
                  <Pill :text="machine.os" class="mr-2" :cssClasses="{ secondary: true }" />
                </b-col>
                <b-col cols="6" md="4" class="mt-1">
                  <span class="font-weight-bold" style="font-size: 0.8rem;">{{ $t('PILLS.score') }}</span><br>
                  {{ machine.points }} {{ $t('PILLS.points') }}
                </b-col>
              </b-row>
              <div class="mt-3">
                <span class="font-weight-bold" style="font-size: 0.8rem;">{{ $t('GENERAL.actions') }}</span><br>

                <span v-if="isMachineBlockedByOther">{{ $t('LAB_MACHINE.ACTIONS.no_actions_because_blocked_by_other_user') }}</span>
                <span v-else-if="isOtherMachineBlockedByMe">{{ $t('LAB_MACHINE.ACTIONS.no_actions_because_you_already_have_other_machine') }}</span>
                <div class="inline-wrapper" v-else>
                  <StartLabMachineModal only-icon v-if="canStartMachine && isAwsMachine" :lab-id="labId" :machine-id="machine.id" @success="machineStartSuccess" @error="machineStartError" class="mr-2" />
                  <LockLabMachine only-icon v-if="canStartMachine && isVMWareMachine" :lab-id="labId" :machine-id="machine.id" @success="machineStartSuccess" @error="machineStartError" class="mr-2" />
                  <LabConsoleLaunchBtn only-icon :lab-id="labId" v-if="hasGuacamole && !isPending" class="mr-2"/>
                  <RevertLabMachineModal only-icon @success="machineRevertSuccess" @error="machineRevertError" v-if="canRevertMachine" :lab-id="labId" :machine-id="machine.id" class="mr-2" />
                  <StopLabMachineModal only-icon @success="machineStopSuccess" @error="machineStopError" v-if="canStopMachine && isAwsMachine" :lab-id="labId" :machine-id="machine.id" class="mr-2" />
                  <UnlockLabMachine only-icon no-event @success="machineStopSuccess" @error="machineStopError" v-if="canStopMachine && isVMWareMachine" :lab-id="labId" :machine-id="machine.id" class="mr-2" />
                  <InsertFlagModal only-icon v-if="canInsertFlag" :lab-id="labId" :machine-id="machine.id" @success="getMachine" class="mr-2" />
                </div>
              </div>
            </template>
          </Card>
        </b-col>
      </b-row>

      <!-- Second row -->
      <b-row>
        <b-col sm="12" md="6" lg="5" offset-md="6" offset-lg="7" class="mt-3">
          <Card no-shadow>
            <template v-slot:title>
              <div class="flex align-items-center mb-3">
                <img src="/media/img/icons/mitre_icon.svg" alt="" style="height: 48px;">
                <h4 class="ml-3 mb-0">{{ $t('TERMS.hints') }}</h4>
              </div>
            </template>
            <template v-slot:content>
              <ul class="custom" v-if="hints.length > 0" style="padding-left: 0; margin-bottom: 0px;">
                <li v-for="hint in hints" v-bind:key="hint.text">{{ hint.text }}</li>
              </ul>
              <span v-else-if="machine.hintsTotal === 0">{{ $t('HINTS.machine_has_no_hints') }}</span>
              <span v-else>{{ $t('HINTS.no_unlocked') }}</span>
              <UnlockHintModal only-icon @success="unlockHintSuccess" @error="unlockHintError" v-if="canUnlockMoreHints" :lab-id="labId" :machine-id="machine.id" class="mt-3" />
            </template>
          </Card>
        </b-col>
        <b-col cols="12" class="mt-3">
          <Card no-shadow>
            <template v-slot:title>
              <div class="flex align-items-center mb-3">
                <!-- <img src="/media/img/icons/mitre_icon.svg" alt="" style="height: 48px;"> -->
                <h4 class="mb-0">{{ $t('PILLS.associated') }}</h4>
              </div>
            </template>
            <template v-slot:content>
              <p>
                {{ $t('MACHINES.pills_associated_description') }}
              </p>
              <PillList :items="relatedPills"/>
            </template>
          </Card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SectionHeader from '@/components/SectionHeader'
import DifficultyPill from '@/components/utils/pills/DifficultyPill'
import StatusPill from '@/components/utils/pills/StatusPill'
import Pill from '@/components/utils/pills/Pill'
import PillList from '@/components/tables/Pills'

import LabConsoleLaunchBtn from '@/components/labs/LabConsoleLaunchBtn'
import InsertFlagModal from '@/components/labs/InsertFlag'
import UnlockHintModal from '@/components/labs/UnlockHint'
import RevertLabMachineModal from '@/components/labs/RevertLabMachine'
import StartLabMachineModal from '@/components/labs/StartLabMachine'
import StopLabMachineModal from '@/components/labs/StopLabMachine'
import LockLabMachine from '@/components/labs/LockLabMachine'
import UnlockLabMachine from '@/components/labs/UnlockLabMachine'

import ToastsMixin from '@/services/mixins/toasts.mixins'
import i18nmixin from '@/services/mixins/i18n.mixins'
import errorsMixin from '@/services/mixins/errors.mixins'

import { LAB_MACHINE_STATUS, LAB_MACHINE_TYPE } from '@/config/constants'

export default {
  components: { SectionHeader, DifficultyPill, StatusPill, PillList, UnlockHintModal, InsertFlagModal, RevertLabMachineModal, Pill, LabConsoleLaunchBtn, StartLabMachineModal, StopLabMachineModal, LockLabMachine, UnlockLabMachine },
  mixins: [ToastsMixin, i18nmixin, errorsMixin],
  methods: {
    ...mapActions({
      getCurrentMachine: 'labs/getCurrentLabMachine',
      startMachineAction: 'labs/startMachine'
    }),
    startMachine () {
      this.loading = true
      return this.startMachineAction({ lab: this.labId, machine: this.machineId })
        .then(_ => { this.getMachine() })
        .then(_ => { this.showSuccessToast(this.$t('LAB_MACHINE.ACTIONS.start_machine_success')) })
        .catch(_ => { this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.start')) })
        .then(_ => { this.loading = false })
    },
    machineStartSuccess () {
      this.getMachine()
        .then(_ => { this.showSuccessToast(this.$t('LAB_MACHINE.ACTIONS.start_machine_success')) })
        .catch(_ => { this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.start')) })
    },
    machineStartError () {
      this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.start'))
    },
    getMachine () {
      return this.getCurrentMachine({ lab: this.labId, machine: this.machineId })
    },
    machineRevertSuccess () {
      this.getMachine()
        .then(_ => { this.showSuccessToast(this.$t('LAB_MACHINE.ACTIONS.revert_success')) })
        .catch(_ => { this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.revert')) })
    },
    machineRevertError (err) {
      if (typeof err.data === 'undefined') return this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.revert'))

      if (typeof err.data.messages !== 'undefined') this.showErrorToast(this.mapRevertMachineError(err.data.messages))

      this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.revert'))
    },
    machineStopSuccess () {
      this.getMachine()
        .then(_ => { this.showSuccessToast(this.$t('LAB_MACHINE.ACTIONS.stop_success')) })
        .catch(_ => { this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.stop')) })
    },
    machineStopError () {
      this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.stop'))
    },
    unlockHintSuccess () {
      this.getMachine()
        .then(_ => { this.showSuccessToast(this.$t('LAB_MACHINE.ACTIONS.UNLOCK_HINT_success')) })
        .catch(_ => { this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.UNLOCK_HINT')) })
    },
    unlockHintError () {
      this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.UNLOCK_HINT'))
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'labs/isLoading',
      canSeeUserConsole: 'config/canSeeUserConsole',
      originalMachine: 'labs/getCurrentLabMachine'
    }),
    machine () { return this.translateObject(this.originalMachine) },
    relatedPills () { return Object.keys(this.machine).indexOf('pills') !== -1 ? this.machine.pills : [] },
    categoriesNice () { return this.relatedPills.reduce((results, item) => { results.push(item.categories); return results }, []).flat().filter(Boolean).slice(0, 2) },
    categoriesMitre () { return this.relatedPills.reduce((results, item) => { results.push(item.categories); return results }, []).flat().filter(Boolean).slice(0, 4) },
    hints () { return this.translateObjectArray(typeof this.machine.hintsRevealed !== 'undefined' ? this.machine.hintsRevealed : []) },
    unlockedHints () { return this.machine.hintsRevealed.length },
    canUnlockMoreHints () { return (this.isInProgress) && this.unlockedHints < this.machine.hintsTotal },
    isPending () { return this.machine.status === LAB_MACHINE_STATUS.PENDING },
    isInProgress () { return this.machine.status === LAB_MACHINE_STATUS.IN_PROGRESS },
    isComplete () { return this.machine.status === LAB_MACHINE_STATUS.COMPLETE },
    labId () { return this.$route.params.id },
    machineId () { return this.$route.params.idMachine },
    hasGuacamole () { return this.canSeeUserConsole }, // TODO Get the property,
    isMachineOn () { return !!this.machine.instanceIp },

    // Connection info
    hasConnectionInfo () { return typeof this.machine.connectionInfo !== 'undefined' && this.machine.connectionInfo.length > 0 },
    translatedConnectionInfo () { return this.getTranslatedValue(this.machine, 'connectionInfo') },
    translatedDescription () { return this.getTranslatedValue(this.machine, 'description') },

    machineType () { return typeof this.machine.source !== 'undefined' ? this.machine.source : LAB_MACHINE_TYPE.AWS },
    isAwsMachine () { return this.machineType === LAB_MACHINE_TYPE.AWS },
    isVMWareMachine () { return this.machineType === LAB_MACHINE_TYPE.VMWARE },

    isMachineBlocked () { return typeof this.machine.isBlocked !== 'undefined' ? this.machine.isBlocked : false },
    isMachineBlockedByMe () { return typeof this.machine.isBlockedByYou !== 'undefined' ? this.machine.isBlockedByYou : false },
    isMachineBlockedByOther () { return this.isMachineBlocked && !this.isMachineBlockedByMe },
    isOtherMachineBlockedByMe () { return typeof this.machine.hasOtherMachineBlocked !== 'undefined' ? this.machine.hasOtherMachineBlocked : false },

    // Machine actions conditions
    // Start
    canStartMachine () { return this.isAwsMachine ? this.canStartAwsMachine : this.canStartVMWareMachine },
    canStartAwsMachine () { return !this.isMachineOn },
    canStartVMWareMachine () { return !this.isOtherMachineBlockedByMe && !this.isMachineBlocked },

    // Revert
    canRevertMachine () { return (this.isVMWareMachine && this.canRevertVMWareMachine) || (this.isAwsMachine && this.canRevertAwsMachine) },
    canRevertVMWareMachine () { return this.isMachineBlockedByMe },
    canRevertAwsMachine () { return !this.isPending && this.isMachineOn },

    // Insert flag
    canInsertFlag () { return (this.isVMWareMachine && this.canInsertFlagVMWareMachine) || (this.isAwsMachine && this.canInsertFlagAwsMachine) },
    canInsertFlagAwsMachine () { return this.isInProgress && this.isMachineOn },
    canInsertFlagVMWareMachine () { return !this.isComplete }, // VMWare machines can insert flag at all times, except when they are finished

    // Stop machine
    canStopMachine () { return (this.isAwsMachine && this.canStopAwsMachine) || (this.isVMWareMachine && this.canStopVMWareMachine) },
    canStopAwsMachine () { return !this.isPending && this.isMachineOn },
    canStopVMWareMachine () { return this.isMachineBlockedByMe }
  },
  created () {
    this.getMachine()

    this.$root.$on('machineStoppedOrUnlocked', (data) => {
      this.getMachine()
    })
  }
}
</script>

<style lang="scss" scoped>
.machine-detail-container {

  &:before {
    content: '';
    display: block;
    height: 430px;
    background-image: url('/media/img/bg/banner_lab_machine.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
</style>
